import React from "react";
import { useParams } from "react-router";
import Container from "react-bootstrap/Container";

import Query from "../../components/Query";
import CATEGORY_ARTICLES_QUERY from "../../queries/category";

import { Helmet } from "react-helmet-async";

import Header from "../../components/Header/category";
import Content from "../../components/Content/category";
import FAQ from "../../components/Faq";
import InfoBlock from "../../components/Content/infoblock";
import AditionalBlock from "../../components/Content/aditional";

const Category = () => {
  let { slug } = useParams();

  return (
    <Query query={CATEGORY_ARTICLES_QUERY} slug={slug}>
      {({ data: { categories } }) => {
        if (categories.data.length) {
          const category = categories.data[0].attributes;

          console.log(category);
          return (
            <>
              <Helmet>
                <title>{category.seo.metaTitle}</title>
                <meta
                  name="description"
                  content={category.seo.metaDescription}
                />
                <meta name="keywords" content={category.seo.keywords} />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={category.seo.metaTitle} />
                <meta
                  property="og:description"
                  content={category.seo.metaDescription}
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta
                  property="og:image"
                  content={
                    process.env.REACT_APP_BACKEND_URL +
                    category.seo.metaImage.data.attributes.url
                  }
                />
              </Helmet>
              <header className="layout layout__min layout__bg layout__bg_primary position-relative overflow-hidden">
                <Container fluid>
                  <Header props={category.header}></Header>
                </Container>
              </header>
              {category.slug === "erste-hilfe" && (
                <AditionalBlock aditional={category.promo} newform={false} />
              )}
              <section
                className={`layout layout__color_primary position-relative ${
                  category.header.categoryname !== "BF17" ? "pb-0" : ""
                }`}
              >
                <Container fluid>
                  <Content
                    obj={category.catinfo}
                    catname={category.header.categoryname}
                  ></Content>
                </Container>
              </section>
              {category.header.categoryname === "BF17" ? (
                <section className="layout layout__bg layout__bg_primary">
                  <InfoBlock bf={category.infoblock}></InfoBlock>
                </section>
              ) : (
                ""
              )}
              <section className="layout layout__color_primary">
                <Container fluid>
                  <h2 data-aos="fade-up" className="">
                    FAQ
                  </h2>
                  <h2 data-aos="fade-up" className="">
                    {category.faq.title}
                  </h2>
                  <FAQ elements={category.faq.qa}></FAQ>
                </Container>
              </section>
            </>
          );
        }
      }}
    </Query>
  );
};

export default Category;
