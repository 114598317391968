import Container from 'react-bootstrap/Container';

import {Helmet} from "react-helmet-async";
import Query from '../../components/Query';
import IMPRESSUM_QUERY from '../../queries/impressum';

import ReactMarkdown from "react-markdown";

const Impressum = () => {
    return(
        <Query query={IMPRESSUM_QUERY}>
            {({ data: { imprint } }) => {
                const elem = imprint.data.attributes;
                return(
                    <>
                        <Helmet>
                            <title>Impressum - Fahrschule-Geesthacht</title>
                            <meta name="description" content="Fahrschule Geesthacht Geesthachter Straße 34 Im Geesthachter Hof 21502 Geesthacht. UST ID: DE335135845. Vertreten durch: Inhaber: Yasin Karaoglan. Kontakt Mobile: 0176 620 504 70" />
                            <meta name="keywords" content="fahrschule geesthacht, geesthacht fahrschule, auto fahren lernen, fahren lernen, führerschein theorie, intensivfahrschule, intensiv fahrschule, fahrschule intensivkurs, theorieprüfung, b197 führerschein, führerschein ab 17, automatik führerschein, BE führerschein"/>
                            <link rel="canonical" href={window.location.href} />
                            <meta property="og:title" content="Impressum - Fahrschule-Geesthacht" />
                            <meta property="og:description" content="Fahrschule Geesthacht Geesthachter Straße 34 Im Geesthachter Hof 21502 Geesthacht. UST ID: DE335135845. Vertreten durch: Inhaber: Yasin Karaoglan. Kontakt Mobile: 0176 620 504 70" />
                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={window.location.href} />
                            {/* <meta property="og:image" content={process.env.REACT_APP_BACKEND_URL + ausbild.seo.metaImage.data.attributes.url} /> */}
                        </Helmet>
                        <main className="layout layout__color_primary">
                            <Container fluid>
                                <h1 data-aos="fade-up" className='layout_mb'>{elem.title}</h1>
                                <div data-aos="fade-up" className='layout_text layout_text__special'>
                                    <ReactMarkdown children={elem.content} />
                                </div>
                            </Container>
                        </main> 
                    </>
                )
            }}
        </Query>

    )
}

export default Impressum;