import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

import IntensivekursBlock from "../../components/Home/intensivekurs";

import Anmelden from "../../components/Content/anmelden";
import Push from "../../components/Push";

import { BoltIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

const Kurs = () => {
  return (
    <>
      <header
        style={{
          backgroundImage: "url(/header-intensivkurs.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          backgroundSize: "cover",
        }}
      >
        <Container fluid className="py-4">
          <Stack gap={4}>
            <h1
              className="header_title- color_light text-center mb-4"
              data-aos="fade-right"
              data-aos-offset="-100"
            >
              Superschnell
              <br />
              zum Führerschein
            </h1>
            <h3
              className="text-center text-white font-semibold mb-4"
              data-aos="fade-right"
              data-aos-offset="-100"
            >
              Schließe mit unserem Intensivkurs
              <br />
              den Theorieunterricht in nur 7 Tagen ab.
            </h3>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-offset="-100"
            >
              <Anmelden
                btnName="Jetzt Platz sichern"
                btn="btn btn__fill btn__fill_primary"
              ></Anmelden>
            </div>
          </Stack>
        </Container>
      </header>
      <Container fluid className="py-5 px-5 text-center">
        <Row>
          <Col>
            <h4 className="mb-2">Was der Kurs beinhaltet:</h4>
            <ul className="text-center list-unstyled">
              <li>12 Stunden Grundstoff</li>
              <li>2 Stunden Zusatzstoff</li>
            </ul>
          </Col>
        </Row>
      </Container>

      <Container fluid className="py-4">
        <div className="text-center">
          <h2 className="mb-8 layout__color_primary">Wir sind ausgezeichnet</h2>

          <div className="m-4">
            {[
              { id: "01" },
              { id: "02" },
              { id: "03" },
              { id: "04" },
              { id: "05" },
            ].map((item) => (
              <StarIcon
                className="d-inline-block color_light align-middle"
                style={{ width: "40px" }}
              />
            ))}
            <span className="fw-semibold ml-2">5,0 bei Google</span>
          </div>
        </div>
        <Row className="py-4 px-5">
          <Col xs={12} md={6} className="mb-5">
            <p className="mb-2 italic">
              “Das gesamte Team ist sehr freundlich und hilfsbereit! Auch der
              Theorieunterricht bringt Spaß und ist verständlich. Die
              Fahrstunden mit Harun waren sehr Lehrreich. Er hat mich gut auf
              die Prüfung, sowie auf das weitere Fahren vorbereitet. Bei
              Anliegen ist immer jemand für einen da. Ich kann jedem diese
              Fahrschule nur weiterempfehlen.”
            </p>
            <p className="font-medium">⸺ Viktoria</p>
          </Col>
          <Col xs={12} md={6}>
            <p className="mb-2 italic">
              “Das ist eine großartige Fahrschule. Mein Fahrlehrer Bellal
              wirklich mega sympathisch ist. Er hat eine super freundliche Art
              und erklärt alles total verständlich. Mit ihm macht das Fahren
              lernen richtig Spaß! Die Fahrschule Bergedorf ist definitiv eine
              Top-Wahl, wenn du eine gute Fahrausbildung suchst. Viel Erfolg bei
              deiner Fahrprüfung!”
            </p>
            <p className="font-medium">⸺ Kerim</p>
          </Col>
        </Row>
      </Container>

      <IntensivekursBlock />

      <Container fluid className="py-4">
        <h2 className="layout__color_primary text-center mb-4">
          Warum den Führerschein bei der Fahrschule Geesthacht machen?
        </h2>
        <div class="hstack gap-3 py-4 px-md-5 justify-content-center">
          {[
            {
              name: "Schnelle Terminvergabe",
              text: "Du bekommst so schnell wie möglich deine Termine.",
              icon: (
                <BoltIcon
                  className="layout__color_primary"
                  style={{ width: "40px" }}
                />
              ),
            },
            {
              name: "Freundliche Fahrlehrer",
              text: "Freundlichkeit und Empathie sind uns besonders wichtig.",
              icon: (
                <FaceSmileIcon
                  className="layout__color_primary"
                  style={{ width: "40px" }}
                />
              ),
            },
            {
              name: "Hochorganisiertes Team ",
              text: "Unsere Flexibilität wirst du lieben.",
              icon: (
                <CheckBadgeIcon
                  className="layout__color_primary"
                  style={{ width: "40px" }}
                />
              ),
            },
          ].map((item) => (
            <div
              key={item.name}
              className="grid grid-col text-center items-start"
            >
              <div className=" justify-self-center mb-4">{item.icon}</div>
              <div className="fw-semibold mb-2">{item.name}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>
      </Container>
      <Push></Push>
    </>
  );
};

export default Kurs;
