import React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// import { ApolloProvider } from "@apollo/react-hooks";

import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';

import App from "./containers/App";

import "./index.scss";

import client from "./utils/apolloClient";

const root = ReactDOM.createRoot(document.getElementById('root'));

  
root.render(
	<HelmetProvider>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </HelmetProvider>
);