import { Container } from "react-bootstrap";
import Anmelden from "../../components/Content/anmelden";
import HomeHeader from "../../components/Header";
import Query from "../../components/Query";
import HOME_QUERY from "../../queries/home";

const NewElements = () => {
  return (
    <>
      <meta name="robots" content="noindex nofollow" />
      <Query query={HOME_QUERY} id={null}>
        {({ data: { home } }) => {
          return <HomeHeader data={home.data.attributes.headerblock} />;
        }}
      </Query>
      <section className="layout layout__bg layout__bg_secondary layout_mt">
        <Container fluid>
          <Anmelden
            btn="btn btn__fill btn__fill_primary layout_mt_min"
            newform={true}
          />
        </Container>
      </section>
      ;
    </>
  );
};

export default NewElements;
