import { Col, Container, Row } from "react-bootstrap";
import HOME_QUERY from "../../queries/home";
import Query from "../Query";
import { Link } from "react-router-dom";

const LeistungenBlock = () => {
  return (
    <Query query={HOME_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <section className="layout layout__color_primary">
            <Container fluid>
              <Row className="justify-content-between mb-5">
                <Col md={6} lg={6} xxl={7} className="mb-5 mb-xl-0">
                  <h2 data-aos="fade-up">
                    {home.data.attributes.Services.title}
                  </h2>
                </Col>
                <Col xxl={4}>
                  <h4 className="h3 tt-normal" data-aos="fade-up">
                    {home.data.attributes.Services.description}
                  </h4>
                </Col>
                <Col xxl={1}></Col>
              </Row>
              <div className="layout_chapters">
                {home.data.attributes.Services.Categories.map((item, index) => {
                  return (
                    <Link
                      to={`/fuehrerscheine/${item.name}`}
                      target="_top"
                      rel="noopener noreferrer"
                      key={item.id}
                      className="layout_chapters_item"
                      data-aos="fade-up"
                    >
                      <Row>
                        <Col
                          xs={
                            index ===
                            home.data.attributes.Services.Categories.length - 1
                              ? 12
                              : 3
                          }
                          md={6}
                          xl={5}
                          xxl={6}
                          className={
                            index ===
                            home.data.attributes.Services.Categories.length - 1
                              ? "order-1 order-md-0 text-end text-md-start text-xxl-end mt-4 mt-md-0"
                              : "text-xxl-end"
                          }
                        >
                          <h3 className="text-xxl-end layout_chapters_title layout_chapters_title_new">
                            {item.name}
                          </h3>
                        </Col>
                        <Col
                          xs={
                            index ===
                            home.data.attributes.Services.Categories.length - 1
                              ? 12
                              : 9
                          }
                          md={6}
                          lg={5}
                          xl={4}
                          xxl={5}
                          className="offset-xl-1 layout_chapters_text"
                        >
                          <p
                            className="layout_text mb-3 mb-xxl-0"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                          <button
                            className={
                              index ===
                              home.data.attributes.Services.Categories.length -
                                1
                                ? "d-none"
                                : "btn btn__border btn__border_primary d-xxl-none"
                            }
                          >
                            Weiterlesen{" "}
                            <img
                              alt="team"
                              className="ms-2"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIkSURBVHgB7ZbBa9RAFMa/SdIWLz2UgiAIHrwqQskmzVpE9GAFt5htL1YQL+pBBcGzFkX/Ai97EBQqVre7xR5cDypKm6XZshf9AwQPBWFRVETLJjO+SG2nvWQmgUKhv8tMPubNx3t5yQywyzbBVBbVis5bGo6vRbQsYZ0cC4KfyIGhskgA/dJDIUI0PaUYm8vYjNk4DR1JKh0+WriDHCiVOqFaLIwYYK9p2rsmCcHE5Phi6ykyoFyuiaC1AMFuShJjgj2sebaNDChn/J+a51Qo6pIkrViwbGq2FWig3SCd1egqFfm9JO2LWLf6cnS0DxpoG19ut7s9vXHSbJ/WRcG8Pz++VaBBpk+i9K7dMQT3afpr3RviQs1zr6nuof2OZepF1yfDWWmfmDr/zNlgqZEWm+sn4AdLdWrt+5Jkcojp58PDB9NicxknfFhs3aJhXpIGTJM/SYvLbZyV3MaHiu5dGkqS9DWOjcm0uFzNNes5ZcZQlfaJuMFPTSwsv0mLzZzxnGcfIdPHkmnySd1QMQUyZvzCcfZGFpZpul+SH5WD8KLqHtoZV4aGeiJTPNtkykRzT//AFWhgQZPBPusB1enYhsI+W134pxuNVY1t9DKue+71LSfTbwFeHgvDL9BE/SIwYp8wuPEKG1WiewDO+c1wBhlQKvWc6x7gXMxsWi9wL6tpglKpY/PfQTAoSfMfm+Ft5EDJmN7Hd+kxpBvH+SmAY5edwF/7lqEUb35KYAAAAABJRU5ErkJggg=="
                            ></img>
                          </button>
                        </Col>
                      </Row>
                    </Link>
                  );
                })}
              </div>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default LeistungenBlock;
